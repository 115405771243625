@use "../custom" as *;
@use "../components/mixins" as *;
@use '../components/fontawesome-icons' as *;

.contact-bg {
  @include gradient;
  color: white;
}

.form-content {
  color: $primary;

  label {
    width: 100%;
  }

  @media (max-width: 636px) {
    padding: 0rem 2rem;
  }

  @media (max-width: 370px) {
    margin-top: 1rem;
  }
}
.text-form-bg {
  background: lighten(#302b63, 65%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.input-form-bg {
  background: white;
  // opacity: 0.9;
}

.contact-me-info {
  text-align: left;
  font-size: 1.4rem;

  margin: 0.5rem 1rem 1rem 1rem;

  h4 {
    margin-left: 1rem;
  }

  p {
    margin: 1rem 1rem;
    text-align: left;
    font-size: 1.7rem;
  }

  @include media-breakpoint-down(sm) {
    p {
      // text-align: center;
      font-size: 1.2rem;
    }
  }

  @include media-breakpoint-down(lg) {
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    h4 {
      font-size: 1.7rem;
      // text-align: center;
    }
  }
}

.form-button {
  background-image: linear-gradient(to right bottom, $secondary, #secondary2);
  border-width: 0rem;
  color: white;
  font-family: 700;
  font-size: 1.2rem;

  &:hover {
    background-color: gold;
    color: $primary;
  }
}

.form-control {
  // border-radius: 1rem;
}
.form-control-lg {
  border-radius: 1rem;
}
