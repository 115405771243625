@use '../custom' as *;
@use '../components/mixins' as *;
@use '../components/fontawesome-icons' as *;

.carousel-inner {
  height: 25rem;

  @media (max-width: 930px) {
    height: auto;
  }
}

.background-bottom--inverse {
  display: block;
  box-sizing: border-box;
  height: 100px;

  @include gradient;

  clip-path: ellipse(63% 100% at 50% 100%);
}

.testimonials {
  position: relative;
  @include gradient;

  h1,
  hr {
    color: white;
  }

  p,
  h3 {
    color: $primary;
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sub-head {
    color: white;
    margin-bottom: 3rem;
  }

  .quotes {
    color: white;
    font-size: 1.5rem;
  }

  &__card {
    background-color: rgba(white, 0.8);
    padding: 2rem 1.25rem;
    border-radius: 0.625rem;
    height: auto;
    text-align: center;

    .ratings {
      font-size: 1.5rem;
      color: $yellow;
      padding-bottom: 1rem;
    }
  }

  &__picture {
    width: 7rem;
    height: 7rem;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
      height: auto;
      // border: 0.325rem solid rgba($secondary, 0.7);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
  }

  &__name {
    padding-top: 5rem;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 500;

    p {
      color: white;
      text-transform: capitalize;
    }

    .t-name {
      color: white;
      text-transform: capitalize;
      font-size: 1.2rem;
    }
  }
}
