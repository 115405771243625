@use '../custom' as *;
@use '../components/mixins' as *;
@use '../components/fontawesome-icons' as *;

.intro-container {
  padding-bottom: 2rem;

  .hand {
    margin-top: 3rem;
    margin-right: 0.2rem;
  }

  @media (max-width: 766px) {
    padding-bottom: 0rem;
  }
}

@media (min-width: 1010px) {
  .desktop-position {
    margin-left: 0.5rem !important;
  }
}

@media (min-width: 1210px) {
  .desktop-position {
    margin-left: 1rem !important;
  }
}

@media (min-width: 1410px) {
  .desktop-position {
    margin-left: 4rem !important;
  }
}

@media (min-width: 1410px) {
  .hand {
    margin-right: -1rem !important;
  }
}

.intro-section {
  @include gradient;
  padding: 10rem 0 0 0;
  width: 100%;
  height: 100%;

  @include media-breakpoint-down(sm) {
    padding: 9rem 0 0rem 0;
  }
}

.intros {
  .video-box {
    position: relative;

    a {
      position: absolute;
      top: 80%;
      left: 50%;
      transform: translate(-50%, -50%);

      span {
        i {
          font-size: 4rem;
          color: $white;
        }
      }
    }
  }
}

.background-bottom {
  display: block;
  box-sizing: border-box;
  height: 100px;

  @include gradient;
  clip-path: ellipse(55% 100% at 50% 0%);
}

.border-animation {
  position: absolute;
  top: 20%;
  right: -50%;
  width: 6.5rem;
  height: 6.5rem;
  border: 0.55rem solid $white;
  border-radius: 50%;
  animation: pulse-border 1.5s linear infinite;

  &--1 {
    animation-delay: 1s;
  }

  &--2 {
    animation-delay: 1.5s;
  }
}
