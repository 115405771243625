@use '../custom' as *;
@use '../components/fontawesome-icons' as *;

.project-showcase-main-heading {
  display: flex;
  align-content: center;

  @include media-breakpoint-down(490px) {
    .icon {
      margin-right: 1rem;
    }
  }
}

.project-showcase {
  position: relative;

  &__content {
    padding: 0rem 1rem;

    .icon {
      font-size: 2.5rem;
      color: $primary;
    }

    .icon-m {
      @media (max-width: 411px) {
        margin-top: 1rem;
      }
    }

    img {
      width: 100%;
      height: auto;
      @include media-breakpoint-down(lg) {
        margin-bottom: 1rem;
      }
    }

    p {
      margin-bottom: 1rem;
    }
  }
}

.project-showcase {
  img:last-child {
    @include media-breakpoint-down(lg) {
      margin-bottom: 0rem;
      width: 100%;
      height: auto;
    }
  }
}
