@use "../custom" as *;
@use "../components/mixins" as *;

.portfolio {
  margin: 0 auto;
  display: flex;

  background: linear-gradient(to right, #24243e, #302b63, #0f0c29);

  width: 100%;
  height: 100%;

  button {
    text-transform: capitalize;
  }

  .portfolio-container {
    border: 2px solid white;
    border-radius: 1rem;

    padding: 1rem 1rem 0rem 1rem;

    // max-height: 1400px;

    // overflow-y: auto;

    // &::-webkit-scrollbar {
    //   width: 1rem;
    // }

    // &::-webkit-scrollbar-track {
    //   background-color: #e4e4e4;
    //   border-radius: 1rem;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: linear-gradient(to right, #0f0c29, #302b63);
    //   border-radius: 100px;
    // }

    @media (max-width: 570px) {
      // margin: 0 2.5rem 0 0;
    }
  }
}

.scroll-text {
  display: none;

  @media (max-width: 569px) {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 1rem;
    // position: absolute;
    // writing-mode: vertical-rl;
    // text-orientation: upright;

    font-weight: 700;
    color: white;

    // top: 19%;
    // bottom: 50%;
    // left: 91.5%;
    // right: 3.5%;
  }
}

// safari has issues rendering gradient-color borders lines
// .with-linear-gradient {
//   border-top-style: solid;
//   border-width: 0.5rem;
//   border-image: linear-gradient(to right, #24243e, #302b63, #0f0c29) 1;
// }

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0px);
  }
}

.portfolio-heading {
  p {
    font-size: 1.3rem;
    font-weight: 500;
    color: white;
  }
}

.portfolio-box {
  position: relative;
  width: 100%;
  height: 18.5rem;
  overflow: hidden;
  margin-bottom: 1.2rem;

  // border-top-style: solid;
  // border-width: 0.5rem;
  // border-color: #302b63;

  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  border-radius: 1rem;

  animation: bounce infinite 5s;

  @media (max-width: 411px) {
    height: 14rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .portfolio-info {
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
    opacity: 0.75;
    // background-color: rgba($secondary2, 0.75);

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    visibility: hidden;

    .caption {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;

      .caption-title {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: bold;
        color: white;

        @media (max-width: 1199px) {
          font-size: 1rem;
        }
      }

      p {
        text-transform: capitalize;
        font-size: 1rem;
        margin-top: 1rem;
      }
    }
  }

  .portfolio-button {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 425px) {
      width: 10.5rem;
    }
  }
}

//?  REVEAL MORE INFO TAB AND MODAL BUTTON

.portfolio-box:hover {
  img {
    transform: scale(1.5);
    @include transition-property;
    width: 100%;
    height: auto;
  }
  .portfolio-info {
    opacity: 1;
    visibility: visible;
    @include transition-property;
  }
}

.portfolio-heading {
  @media (max-width: 348px) {
    p {
      margin-bottom: 2rem;
    }
  }
}

.filter-buttons-container {
  width: 45%;
  margin: 0 auto;

  @media (max-width: 1199px) {
    width: 40%;
  }

  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 65%;
  }
  @media (max-width: 538px) {
    width: 70%;
  }
  @media (max-width: 490px) {
    width: 80%;
  }
  @media (max-width: 433px) {
    width: 90%;
  }
  @media (max-width: 409px) {
    width: 100%;
  }
}

.filter-buttons {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  .frame {
    margin: 0.5rem 0rem;
  }

  #all {
    order: 4;
  }

  #hide {
    order: 5;
  }

  #apps {
    order: 2;
  }

  #websites {
    order: 1;
  }

  #components {
    order: 3;
  }
}
