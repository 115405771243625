@use '../custom' as *;
@use '../components/mixins' as *;
@use '../components/fontawesome-icons' as *;

.faq-main-heading {
  margin-top: 4rem;

  p {
    margin-top: 1rem;
  }
}

.accordion-body {
  p {
    color: #302b63;
  }
}

.faq-q-form {
  .faq-q-title {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.5rem;
    color: #302b63;
    font-weight: bold;
  }
  form {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 0.5rem;

    justify-content: center;
    justify-items: center;

    text-align: center;

    label {
    }

    input {
      width: 20rem;

      @media (min-width: 798px) {
        width: 25rem;
      }

      &::placeholder {
        color: rgb(179, 175, 175);
        opacity: 1; /* Firefox */
      }
    }

    textarea {
      width: 20rem;

      @media (min-width: 798px) {
        width: 25rem;
      }

      &::placeholder {
        color: rgb(179, 175, 175);
        opacity: 1; /* Firefox */
      }
    }

    button {
    }
  }
}
