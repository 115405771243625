@use "../custom" as *;
@use "../components/mixins" as *;
@use '../components/fontawesome-icons' as *;

nav {
  margin: 0 auto;
}

.navbar-brand {
  color: $white !important;
}

.menu {
  @include gradient;

  li {
    padding: 0 0.4rem;

    a {
      color: $white !important;
      text-transform: capitalize;
      font-weight: 300;
      white-space: nowrap;
      font-size: 1rem !important;
    }
  }

  button {
    font-weight: 300;
    margin-left: 2rem;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}
