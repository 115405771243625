@use '../custom' as *;
@use '../components/mixins' as *;
@use '../components/fontawesome-icons' as *;

.companies {
  margin-bottom: 3rem;
}
.carousel-inner {
  img {
    display: inline-block;
    width: 10rem;
    height: auto;

    &:hover {
      filter: grayscale(120);
    }
  }
}

#company-slide {
  text-align: center;
  width: 40%;
  height: 6rem;
  margin: 0 auto;
}
