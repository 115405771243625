@use '../custom' as *;

//?GLOBAL FONTS

body {
  font-family: 'Lato', sans-serif !important;
  font-weight: 300;
}

.display-2 {
  margin-bottom: 1rem;
  text-transform: capitalize;

  &--intro {
    display: inline-block;
    font-weight: 700;
    font-size: 4rem;
    color: $white;
  }

  &--description {
    font-size: 1rem;
    display: block;
    color: $white;
    margin-top: 1.2rem;
    text-transform: none;
    font-size: 1.5rem;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      padding: 0 3rem;
    }
  }
}

.display-3 {
  &--title {
    font-size: 1.8rem;
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    background-image: linear-gradient(to right, #24243e, #302b63, #0f0c29);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 1rem;
    margin-bottom: 1rem;

    @include media-breakpoint-down(md) {
      white-space: normal;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 0rem;
      margin-bottom: 1rem;
    }
  }
}

//? NAVBAR

.menu {
  li {
    a {
      font-weight: 300;
    }
  }

  button {
    font-weight: 300;
  }
}

.navbar-brand {
  //!LOGO
  font-weight: 500;
}

//? WORKED WITH - LINE & OTHER

.heading-line::before {
  content: '';
  max-width: 15rem;
  height: 0.1rem;
  display: block;
  margin: 0 auto;
  background-color: $primary;
}

.heading-line::after {
  content: '';
  max-width: 5rem;
  padding-top: 0.5rem;
  height: 0.2rem;
  display: block;
  margin: 0 auto;
  background-color: $primary;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
}

.separator {
  border-bottom: 0.1rem solid $gray-300;
  width: 100%;
}

.text-gradient {
  background-image: linear-gradient(to right, #2f3e24, #302b63, #0f0c29);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.h2-heading {
  color: white;
}

//? stop holding back charity app icon (temp hack)
.project-showcase-main-heading {
  img {
    width: 3rem !important;
    height: 3rem !important;
    // padding-top: 1rem !important;
  }
}
