@use "../custom" as *;

// ////////////////////////////////////////////////////////////////////////////////////////
//GRADIENT MIXIN
/////////////////////////////////////////////////////////////////////////////////////////////-->

@mixin gradient {
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
}

////////////////////////////////////////////////////////////////////////////////////////
//ABSOLUTE CENTER
/////////////////////////////////////////////////////////////////////////////////////////////-->

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// transistion property

@mixin transition-property {
  transition-duration: 1s;
  transition-property: all;
}

.hide {
  display: none;
}

.show {
  display: block;
}

#about-me,
#contact-me,
#key-projects,
#testimonials,
#faqs,
#portfolio,
#join-us::before {
  content: "";
  display: block;
  height: 130px;
  margin-top: -130px;
  visibility: hidden;
}
