@use '../custom' as *;
@use '../components/mixins' as *;
@use '../components/fontawesome-icons' as *;

.f-email,
.f-location {
  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }
}

.footer {
  padding-top: 1rem;
}

.bg-color-footer-section {
  background: #f5f5f5;
}
.contact-box {
  padding: 1rem;

  @media (max-width: 991px) {
    flex-direction: column;
  }

  &__info {
    font-size: 1.3rem;
    white-space: nowrap;
  }

  &__subtitle {
    text-transform: capitalize;
    font-weight: bold;
    color: $primary;

    @media (max-width: 991px) {
      text-align: left;
    }
  }

  &__flex {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 991px) {
      justify-content: start;
      align-items: center;
    }

    .flex-2 {
      display: flex;
      flex-direction: column;

      @media (max-width: 991px) {
        align-items: flex-start;
      }
    }

    svg {
      margin-right: 1rem;
      stroke: $primary;
    }
  }
}

.footer-link {
  text-decoration: none;
}

// ? SOCIAL MEDIA FOOTER

.social-media-footer {
  background-color: white;
  color: $primary;

  .row-sm-container {
    justify-content: center;
    align-items: center;
  }

  .text-area {
    display: flex;

    justify-content: center;
    align-items: center;

    .text {
      font-size: 1.5rem;
      @media (max-width: 391px) {
        font-size: 1.2rem;
      }
    }
  }

  .sm-icons {
    display: flex;
    justify-content: center;
    font-size: 2rem;

    @media (max-width: 766px) {
      margin-top: 1rem;
    }

    .fa-github {
      margin-right: 1rem;
    }

    .fa-twitter {
      margin-right: 1rem;
    }
  }

  .copy-right {
    text-align: center;

    font-size: 1rem;

    margin-bottom: 2rem;
  }

  .flex-3 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

//? blog container fix
.blog-footer-container {
  @media (max-width: 1199px) {
    padding-left: 2rem;
  }

  @media (max-width: 986px) {
    padding-left: 0rem;
  }
}
.blog-footer-container__2 {
  @media (max-width: 993px) {
    margin-top: 1rem;
  }
}
.blog-link {
  text-decoration: none;
}

.blog-box {
  margin: 0 auto;

  @media (max-width: 991px) {
    margin: 0 0;
  }
}

/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
  width: 2.5rem;
  height: 3rem;
  margin-left: 0.1rem;
  fill: #2c3e50;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #2c3e50;
}

.svg-icon circle {
  stroke-width: 1;
}
