@use '../custom' as *;
@use './mixins' as *;

.btn-rounded {
  @include media-breakpoint-down(xxl) {
    display: none;
  }

  position: relative;
  padding: 0.9375rem 4.375rem 0.9375rem 1.25rem;
  font-weight: 700;
  margin: 0;
  border: 0.1rem solid rgba(white, 0.6);
  background-color: $white;

  span {
    position: absolute;
    background-color: $primary;
    width: 2.5rem;
    height: 2.5rem;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    border-radius: 50%;

    i {
      color: $white;
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }

  &:hover {
    @include gradient;
    color: $white;

    span {
      background-color: rgba($black, 0.6);
    }
  }

  &--project-showcase {
    @include media-breakpoint-down(xxl) {
      display: inline-block;
    }

    &--project-showcase {
      @include media-breakpoint-down(373px) {
        display: inline-block;
      }
    }
  }
}

.btn-container {
  display: flex;
  justify-content: start;
  white-space: nowrap;

  @include media-breakpoint-down(sm) {
    flex-direction: column;

    align-items: flex-start;

    button:first-child {
      margin-bottom: 1rem;
    }

    .btn2 {
      margin-left: 0rem;
    }
  }
}

.rounded-pill--mod {
  border: 0.5px solid;
  border-color: linear-gradient(to right, #24243e, #302b63, #0f0c29);
}

.btn2 {
  margin-left: 3rem;
}

.btn-outline-primary {
  color: white;
  border-color: white;
}

// ? resume button

.r-container {
  display: flex;
  flex-direction: column;
  min-height: 45vh;
  padding-top: 5rem;

  align-content: flex-start;
}

.btn-resume {
  margin: auto;
  width: 20rem;
  border-radius: 5rem;
  height: 3rem;
  font-size: 1.5rem;
  background: transparent;
  border: none;
  color: #302b63;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: background-color 0.5s, color 0.5s;
  text-align: center;
  text-decoration: none;
  line-height: 2.9rem;
  font-weight: bold;

  &:hover {
    background-color: #302b63;
    color: white;
  }
}

#rbtn-1 {
  margin-top: 3rem;
}

#rbtn-2 {
  margin-bottom: 3rem;
}
